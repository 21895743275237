:root {
  /* Primary Theme Colors */
  --color-primary: #949494;
  --color-primary-hover: #747474;
  --color-primary-light: #9494941a;
  --color-primary-lighter: #9494940d;
  --color-primary-lightest: #94949433;
  
  /* Text Colors */
  --color-text-primary: #333;
  --color-text-secondary: #444;
  --color-text-tertiary: #666;
  --color-text-light: #777;
  --color-text-lightest: #aaa;
  
  /* Background Colors */
  --color-background-main: #f9f9f9;
  --color-background-card: #f8f8f8;
  --color-background-light: #fafafa;
  --color-background-header: #f0f0f0;
  --color-background-dark: #535353;
  
  /* Border Colors */
  --color-border-light: #eee;
  --color-border: #ddd;
  
  /* UI Colors */
  --color-white: #FFFFFF;
  --color-overlay: rgba(0, 0, 0, 0.75);
  --color-shadow: rgba(0, 0, 0, 0.1);
  --color-shadow-dark: rgba(0, 0, 0, 0.4);
  
  /* Loader Colors */
  --color-loader-background: rgba(94, 93, 240, 0.2);
  --color-loader: #ffd17c;
}

body, p, h1, h2, li, label {
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
}

.App {
  text-align: center;
  width: 100vw;
  height:100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* App Menu */
  .icon-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .icon-menu-panel {
    position: fixed;
    top: 0;
    left: -190px; /* Initially off-screen */
    width: 150px;
    height: 100vh;
    background-color: var(--color-white);
    transition: transform 0.3s ease;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .icon-menu-panel.open {
    transform: translateX(190px); /* Slide in from left */
  }

  .icon-menu-button {
    background: none;
    border: none;
    padding: 10px;
    font-size: 16px;
  }

/* Main App Container */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--color-background-dark);
}

/* Header Styles */
.app-header {
  background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-hover) 100%);
  color: var(--color-white);
  padding: 16px 24px;
  box-shadow: 0 2px 10px var(--color-shadow);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  
  .header-buttons {
    width: 100%;
    justify-content: end;
  }
  
  .app-title {
    font-size: 1.3rem;
  }
}

.app-title {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 600;
  font-family: 'Saira', sans-serif;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.header-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--color-white);
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.header-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* About Section */
.about-section {
  width: 100%;
}

/* Geeklist Body Section */
.geeklist-body-container {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-border-light);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.geeklist-body-toggle {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  flex-direction: row;
  gap: 4px;
  min-width: 80px;
  align-items: center;
  justify-content: center;
}

.geeklist-body-toggle:hover {
  background-color: var(--color-primary-hover);
}

.geeklist-body-content {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

.geeklist-body-content h2 {
  color: var(--color-text-primary);
  margin-top: 0;
  margin-bottom: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border);
  font-size: 1.5rem;
}

.geeklist-body {
  color: var(--color-text-secondary);
  line-height: 1.6;
  font-size: 0.95rem;
  text-align: left;
}

.geeklist-body a {
  color: var(--color-primary);
  text-decoration: none;
}

.geeklist-body a:hover {
  text-decoration: underline;
}

.about-details {
  border-radius: 8px;
  margin-top: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.about-card {
  background-color: var(--color-background-card);
  border-radius: 6px;
  padding: 16px;
  border-left: 4px solid var(--color-primary);
}

.about-title {
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--color-text-primary);
}

.about-list {
  margin: 0;
  padding-left: 20px;
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--color-text-secondary);
  text-align: left;
}

.about-list li {
  margin-bottom: 8px;
}

.about-footer {
  text-align: right;
  font-size: 0.8rem;
  color: var(--color-white);
  padding: 8px 0;
}

.author-link {
  color: var(--color-primary);
  text-decoration: none;
}

.author-link:hover {
  text-decoration: underline;
}

/* Button Styles */
button {
  background: var(--color-primary);
  border-radius: 6px;
  box-sizing: border-box;
  color: var(--color-white);
  cursor: pointer;
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
  font-size: 0.9rem;
  line-height: 1.5;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  transition: background-color 0.2s, transform 0.1s;
}

button:hover:not(:disabled) {
  background: var(--color-primary-hover);
}

button:active:not(:disabled) {
  transform: translateY(1px);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Form Controls */
label {
  font-size: 0.9rem;
  color: var(--color-text-secondary);
  margin-bottom: 4px;
  display: block;
}

input {
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid var(--color-border);
  font-size: 0.9rem;
  transition: border-color 0.2s;
  width: 100%;
  box-sizing: border-box;
}

input:focus {
  border-color: var(--color-primary);
  outline: none;
  box-shadow: 0 0 0 2px var(--color-primary-lightest);
}

input::placeholder {
  color: var(--color-text-lightest);
}

/* Controls Panel */
.controls-panel {
  background-color: var(--color-white);
  padding: 16px;
  border-bottom: 1px solid var(--color-border-light);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.control-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.control-item {
  flex: 1;
  min-width: 200px;
}

.seller-list-control {
  flex-grow: 0;
}

.control-label {
  font-weight: 500;
}

.control-input-group {
  display: flex;
  gap: 8px;
  align-items: center;
}

.control-input {
  flex: 1;
  padding: 8px 30px 8px 8px;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: gray;
  font-size: 16px;
  cursor: pointer;
}

.clear-button:hover {
  color: red;
}


.control-button {
  white-space: nowrap;
}

.primary-button {
  background-color: var(--color-primary);
  font-weight: 500;
}

.control-divider {
  width: 1px;
  height: 30px;
  background-color: var(--color-border);
  margin: 0 8px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

/* Modal Content */
.modal {
  background-color: var(--color-white);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px var(--color-shadow-dark); /* Enhanced drop shadow */
  max-width: 85%;
  max-height: 85%;
  overflow: auto; /* Enable scrolling if content overflows */
  z-index: 100;
  min-width: 320px;
  width: 80%;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--color-primary);
  padding-bottom: 20px;
}

.modal-title {
  font-size: 1.8em;
  margin: 0;
  color: var(--color-text-primary);
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

/* Close Button */
.close-button {
  background: var(--color-primary);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: #e0e0e0;
}

/* Game Modal Styles */
.modal-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  text-align: center;
}

.game-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.modal-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.3em;
  margin: 0 0 15px 0;
  color: var(--color-text-secondary);
}

.price-history {
  background-color: var(--color-background-card);
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 15px;
  font-size: 0.9em;
  color: #555;
}

.price-history a {
  color: var(--color-primary);
  text-decoration: none;
}

.price-history a:hover {
  text-decoration: underline;
}

.game-description {
  background-color: var(--color-white);
  padding: 15px;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  line-height: 1.5;
  text-align: left;
}

.comments-table-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid var(--color-border);
  border-radius: 6px;
}

.comments-table {
  width: 100%;
  border-collapse: collapse;
}

.comments-table th {
  background-color: var(--color-background-header);
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.comments-table td {
  padding: 12px;
  vertical-align: top;
}

.comment-body {
  text-align: left;
  line-height: 1.4;
}

.no-comments {
  color: var(--color-text-light);
  font-style: italic;
  text-align: center;
  padding: 20px;
  background-color: var(--color-background-main);
  border-radius: 6px;
}

@media (max-width: 768px) {
  .modal {
    padding: 15px;
    max-width: 95%;
    width: 95%;
  }
  
  .modal-title {
    font-size: 1.5em;
  }
  
  .comments-table-container {
    max-height: 250px;
  }
}

/* Table Info */
.table-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-border-light);
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .table-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .loading-inline-container {
    margin-left: 0;
    width: 100%;
  }
  
  .loading-inline-content {
    justify-content: flex-start;
  }
}

.row-count {
  font-weight: 600;
  color: var(--color-text-primary);
}

/* Table Container */
.table-container {
  flex: 1;
  overflow: hidden;
  background-color: var(--color-white);
  border-radius: 8px;
  margin: 16px;
  box-shadow: 0 2px 8px var(--color-shadow);
}

/* AG Grid Custom Theme */
.ag-theme-alpine {
  --ag-header-background-color: var(--color-background-header);
  --ag-header-foreground-color: var(--color-text-primary);
  --ag-header-cell-hover-background-color: #e0e0e0;
  --ag-row-hover-color: #f5f5f5;
  --ag-selected-row-background-color: var(--color-primary-light);
  --ag-font-size: 14px;
  --ag-font-family: Inter, Helvetica, sans-serif;
  --ag-border-color: var(--color-border);
  
  height: 100%;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.ag-theme-alpine .ag-header {
  font-weight: 600;
}

.ag-theme-alpine .ag-header-cell {
  padding: 10px 16px;
}

.ag-theme-alpine .ag-cell {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  height: 100%;
}

.ag-theme-alpine .ag-row {
  border-bottom: 1px solid var(--color-border-light);
  height: 48px;
}

.ag-theme-alpine .ag-row-even {
  background-color: var(--color-background-light);
}

.ag-theme-alpine .ag-row:hover {
  background-color: var(--color-primary-lighter);
}

.ag-theme-alpine.ag-popup {
  position: absolute;
}

.data-grid {
  height: 100%;
  width: 100%;
}

/* Custom button in grid */
.ag-theme-alpine button {
  font-size: 0.8rem;
  padding: 6px 12px;
}

tbody tr:nth-child(even) {
  background-color: #e8e8e8;
}

table, th, td {
  border-collapse: collapse;
}

th, td {
  border: 1px solid var(--color-border);
}

table td:first-child, 
table th:first-child {
  border-left: none;
}

table td:last-child, 
table th:last-child {
  border-right: none;
}

table tr:first-child td, 
table tr:first-child th {
  border-top: none;
}

table tr:last-child td, 
table tr:last-child th {
  border-bottom: none;
}

td {
  padding: 10px;
}

/* loading animation */
.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: spin 2s linear infinite;
}

/* full page loading indicator classes */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: var(--color-white);
  z-index: 100;
}

.loading-overlay {
  position: absolute;
}

.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px; 
}

/* Loading Indicators */
.loading-inline-container {
  margin-left: auto;
}

.loading-inline-content {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--color-primary);
  font-weight: 500;
  font-size: 0.9rem;
}

.loading-message {
  white-space: nowrap;
}

.progress-bar-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.progress-value {
  min-width: 50px;
  text-align: right;
  font-weight: 600;
}

.progress-bar {
  width: 150px;
  height: 8px;
  background-color: var(--color-border-light);
  border-radius: 4px;
  overflow: hidden;
}

.progress {
  height: 100%;
  width: 0%;
  background-color: var(--color-primary);
  border-radius: 4px;
  transition: width 0.3s ease;
}

/* Loader Animation */
.loader {
  border: 3px solid var(--color-loader-background);
  border-top: 3px solid var(--color-loader);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .control-group {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
  }
  
  .control-divider {
    width: 100%;
    height: 1px;
    margin: 4px 0;
  }
  
  .control-input-group {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }
  
  .control-button {
    width: 100%;
    margin-top: 4px;
  }
  
  .control-item {
    min-width: 100%;
  }
  
  .table-container {
    margin: 8px;
    min-height: 500px;
  }
  
  .controls-panel {
    padding: 12px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: var(--color-white);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
